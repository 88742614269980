import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { ReportComponent } from "./report/report.component";
import { TextFieldModule } from '@angular/cdk/text-field';
import { TYTCommonModule } from "@tytapp/common";
import { HideAsStaffComponent } from './hide-as-staff/hide-as-staff.component';

const COMPONENTS = [
    ReportComponent,
    HideAsStaffComponent
];

@NgModule({
    declarations: COMPONENTS,
    exports: COMPONENTS,
    imports: [
        CommonModule,
        FormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatSelectModule,
        TextFieldModule,
        TYTCommonModule
    ]
})
export class ModerationModule {

}