import { Injectable, inject } from '@angular/core';
import { CDNProvider, DataURICDNProvider } from '@banta/common';
import { ImageAssetsApi } from '@tytapp/api';
import { LoggerService } from '@tytapp/common';

@Injectable()
export class TYTBantaCDNProvider implements CDNProvider {
    private imageAssetApi = inject(ImageAssetsApi);
    private logger = inject(LoggerService);

    async uploadImage(image: Blob): Promise<string> {
        // TODO: implement image conversion and block unexpected mimetypes
        try {
            let asset = await this.imageAssetApi.upload({
                summary: 'Uploaded via Banta',
                url: await new DataURICDNProvider().uploadImage(image)
            }).toPromise();

            return asset.url;
        } catch (e) {
            if (e.json)
                e = e.json();

            this.logger.error(`Failed to upload image:`);
            this.logger.error(e);

            if (e.error === 'nonmember') {
                throw new Error(`Become a TYT member in order to upload images.`);
            } else if (e.error === 'unconfirmed') {
                throw new Error(`Only confirmed users can upload images. Please check your email for instructions on how to confirm your account.`);
            } else {
                throw new Error(`Could not upload image: ${e.message || e.error || 'An unknown error occurred'}`);
            }
        }
    }

}