import { Component, Input } from "@angular/core";
import { TYTBantaAuthentication } from "../tyt-banta-authentication";

@Component({
    selector: 'tyt-chat',
    templateUrl: './chat.component.html',
    styleUrls: ['./chat.component.scss']
})
export class TYTChatComponent {
    constructor(
        tytBantaAuth: TYTBantaAuthentication
    ) {
    }

    @Input()
    identifier: string;
}