@if (state === 'loading') {
  <app-spinner></app-spinner>
}
@if (state === 'disabled') {
  <!-- Comments are disabled in this environment -->
}
@if (state === 'missing-url') {
  <div class="error-statement">
    <mat-icon>error</mat-icon>
    An issue prevents this comments section from loading.
    <div class="technical-details">
      Site operator: <code>missing_explicit_url</code>
    </div>
  </div>
}
@if (state === 'ready') {
  @if (showHeader) {
    <h1>Conversation</h1>
  }
  @if (requirementType === 'membership' && !isMember) {
    <div class="alert alert-membership">
      Want to put your two cents in? We want to hear it! Posting comments is only available for <strong>TYT
    members</strong> in good standing.
    <br/>
    <br/>
    <a target="_blank" routerLink="/billing/membership-benefits">Click here to learn more about becoming a TYT member.</a>
  </div>
}
@if (requirementType === 'registration' && !user) {
  <div class="alert alert-membership">
    Want to put your two cents in? We want to hear it! Posting comments is only available for <strong>TYT
  community members</strong>.
  <br/>
  <br/>
  <a target="_blank" routerLink="/signup">Click here to sign up for free.</a>
</div>
}
@if (identifier) {
  <banta-comments
    topicID="{{identifier}}_comments"
    (signInSelected)="showSignIn()"
    [url]="url"
    [maxMessages]="2000"
    [maxVisibleMessages]="200"
    [customMenuItems]="customMenuItems"
    (permissionDeniedError)="showPermissionDenied($event)"
    (editAvatarSelected)="editAvatar()"
    (reported)="reportMessage($event)"
    (usernameSelected)="navigateToPublicProfile($event)"
    (avatarSelected)="navigateToPublicProfile($event)"
    (shared)="shareComment($event)"
    [postReplyLabel]="postReplyLabel"
    permissionDeniedLabel="Become a Member to Comment"
    [hashtags]="hashtags"
    [shouldInterceptMessageSend]="interceptReplySend.bind(this)"
    [allowAttachments]="true"
    [genericAvatarUrl]="'/assets/new-avatar.png'"
    [sortOrder]="defaultSortOrder"
    [metadata]="metadata"
    >
    <div *bantaReplySendOptions>
      @if (canPrivateReply) {
        @if (!isAPrivateReply) {
          <a class="send-mode" href="javascript:;"
            matTooltip="This reply will be sent publically."
            (click)="setPrivateReplyMode()">
            <mat-icon>public</mat-icon>
            Public
          </a>
        }
        @if (isAPrivateReply) {
          <a
            class="send-mode"
            href="javascript:;"
            matTooltip="This reply will be sent as an email to the user."
            (click)="setPublicReplyMode()">
            <mat-icon>lock_outline</mat-icon>
            Private
          </a>
        }
        <a class="send-mode-info-button" (click)="isAPrivateReply ? showPrivateReplyInfo() : showPublicReplyInfo()">
          <mat-icon>help</mat-icon>
        </a>
      }
    </div>
  </banta-comments>
}
<ng-template cdkPortal #selectorPanelTemplate="cdkPortal">
  <div class="share-container">
    <a class="close-btn" href="javascript:;" (click)="overlayRef.detach()"><mat-icon>close</mat-icon></a>
    @if (!disableSharing) {
      <tyt-social-buttons [url]="sharedCommentURL" [title]="shareTitle"></tyt-social-buttons>
    }
    @if (disableSharing) {
      <div class="no-sharing-message">
        Sharing to social media is disabled on this version of TYT.com. Please do not share URLs to this website
        externally
      </div>
    }
  </div>
</ng-template>
<tyt-staging-note>
  Topic ID: <code>{{identifier}}_comments</code>
</tyt-staging-note>
}
