import { ModuleWithProviders, NgModule, inject } from "@angular/core";
import { BantaSdkModule } from "@banta/sdk";
import { TYTBantaAuthentication } from "./tyt-banta-authentication";
import { TYTChatComponent } from "./chat/chat.component";
import { TYTCommentsComponent } from "./comments/comments.component";
import { CommonModule } from "@angular/common";
import { CommonUiModule } from "@tytapp/common-ui";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatButtonModule } from "@angular/material/button";
import { ModerationModule } from "@tytapp/moderation";
import { CommentsCountComponent } from './comments-count/comments-count.component';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ModalSocialButtonsComponent } from './modal-social-buttons/modal-social-buttons.component';
import { ShareCommentComponent } from './share-comment/share-comment.component';
import { CDNProvider } from '@banta/common';
import { TYTBantaCDNProvider } from './tyt-banta-cdn-provider';
import { RouterModule } from '@angular/router';
import { TYTCommonModule } from '@tytapp/common';
import { PortalModule } from '@angular/cdk/portal';
import { ContentBlocksService, ContentModule } from '@tytapp/content';
import { COMMENTS_BLOCK_TYPE } from './comments-block';
import { FormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';

const DECLARATIONS = [
    TYTChatComponent,
    TYTCommentsComponent,
    CommentsCountComponent,
    ModalSocialButtonsComponent,
    ShareCommentComponent
];

@NgModule({
    declarations: DECLARATIONS,
    exports: DECLARATIONS,
    imports: [
        CommonModule,
        ContentModule,
        FormsModule,
        TYTCommonModule,
        CommonUiModule,
        RouterModule,
        BantaSdkModule,
        ModerationModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatIconModule,
        MatTooltipModule,
        MatSelectModule,
        MatCheckboxModule,
        PortalModule
    ],
})
export class ChatModule {
    constructor() {
        ContentBlocksService.register(COMMENTS_BLOCK_TYPE);
    }

    static forRoot(): ModuleWithProviders<ChatModule> {
        return {
            ngModule: ChatModule,
            providers: [
                TYTBantaAuthentication,
                { provide: CDNProvider, useClass: TYTBantaCDNProvider }
            ]
        };
    }
}