import { Component, inject } from '@angular/core';
import { ApiModerationReason, ModerationApi } from '@tytapp/api';
import { DialogComponent, NamedDialog, PagedArray } from '@tytapp/common';
import { environment } from '@tytapp/environment';
import { MODERATION_REASONS } from '../moderation-reason';

@NamedDialog('hideAsStaff')
@Component({
    templateUrl: './hide-as-staff.component.html',
    styleUrls: ['./hide-as-staff.component.scss']
})
export class HideAsStaffComponent extends DialogComponent {
    private moderationApi = inject(ModerationApi);

    async init(reference: { subjectType?: string, subjectId?: number, externalId?: string }) {
        this.subjectType = reference.subjectType;
        this.subjectId = reference.subjectId;
        this.externalId = reference.externalId;

        let reasons: PagedArray<ApiModerationReason>;

        try {
            reasons = await this.moderationApi.getModerationReasons().toPromise();
            this.reasons = reasons.map(x => ({
                id: x.key,
                name: x.reason,
                description: ''
            }));
        } catch (e) {
            this.logger.error(`Failed to fetch moderation reasons, using static set!`);
            return;
        }

        this.loading = false;
    }

    loading = true;

    subjectType: string;
    subjectId: number;
    externalId: string;

    reason: string;
    additionalDetails: string;
    sending = false;
    sent = false;

    reasons = MODERATION_REASONS;

    get coreValuesUrl() {
        return `${environment.urls.accounts}/core-values`;
    }

    get valid() {
        return !!this.reason;
    }

    async send() {
        if (this.sending)
            return;
        this.sending = true;

        try {
            // TODO
            await this.moderationApi.hideContentAsStaff({
                subject_type: this.subjectType,
                subject_id: this.subjectId,
                external_id: this.externalId,
                reason: this.reason,
                additional_comments: this.additionalDetails
            }).toPromise();
            this.sent = true;
        } finally {
            this.sending = false;
        }
    }

    close() {
        this.shell.hideDialog();
    }
}