export interface ModerationReason {
    id: string;
    name: string;
    description: string;
}

export const MODERATION_REASONS: ModerationReason[] = [
    {
        id: 'fraud',
        name: 'Fraud',
        description: ''
    },
    {
        id: 'hate-speech',
        name: 'Hate Speech',
        description: ''
    },
    {
        id: 'misrepresentation',
        name: 'Misrepresentation of Identity',
        description: ''
    },
    {
        id: 'nudity',
        name: 'Nudity',
        description: ''
    },
    {
        id: 'sexual-activity',
        name: 'Sexual Activity',
        description: ''
    },
    {
        id: 'spam',
        name: 'Spamming',
        description: ''
    },
    {
        id: 'threat',
        name: 'Threats, explicit or implicit',
        description: ''
    },
    {
        id: 'solicitation',
        name: 'Unsolicited Advertising',
        description: ''
    },
];