<h1>Report Content</h1>
@if (loading) {
  <app-spinner></app-spinner>
}
@if (!loading) {
  <br />
  <br />
  @if (sent) {
    <div class="sent">
      <p>Report received.</p>
      <button mat-raised-button (click)="close()">
        Close
      </button>
    </div>
  }
  @if (!sent) {
    @if (sending) {
      <app-spinner></app-spinner>
    }
    @if (!sending) {
      <form (submit)="send()">
        <div>
          <mat-form-field appearance="outline" floatLabel="always">
            <mat-label>Reason</mat-label>
            <mat-select name="reason" matInput [(ngModel)]="reason" placeholder="Choose a reason">
              @for (reason of reasons; track reason) {
                <mat-option [value]="reason.id">{{reason.name}}</mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>
        <div>
          <mat-form-field appearance="outline" floatLabel="always">
            <mat-label>Additional Details</mat-label>
            <textarea matInput name="additionalDetails" [(ngModel)]="additionalDetails" cdkTextareaAutosize></textarea>
          </mat-form-field>
        </div>
        <div>
          <button mat-raised-button [disabled]="!valid">
            Send Report
          </button>
        </div>
      </form>
      <footer>
        Review our <a [href]="coreValuesUrl" target="_blank">Core Values</a><br />
        {{ subjectType }} {{ subjectId || externalId }}
      </footer>
    }
  }
}