<h1>Share Comment</h1>

<tyt-social-buttons
  [url]="url"
  [title]="title"
  (shareDone)="onShareComment($event)"
  ></tyt-social-buttons>
<banta-comment [message]="message"></banta-comment>

<div style="text-align: center;">
  <button mat-raised-button (click)="close()">
    Back
  </button>
</div>