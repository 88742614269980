<h1>Hide Content</h1>

<p>As TYT.com staff, it is possible for you to immediately hide content without waiting for a moderator to review your
  report. This ability is provided for extreme circumstances and should not be regularly used unless you are part of the
  moderation team. When you submit this request, a new report will be created against the content attached to your TYT
  staff account, meaning the moderation team will review your action as if it was a regular report. They may opt to
reverse your decision. All unilateral staff actions are reviewed.</p>

@if (loading) {
  <app-spinner></app-spinner>
}
@if (!loading) {
  <br />
  <br />
  @if (sent) {
    <div class="sent">
      <p>Content hidden.</p>
      <button mat-raised-button (click)="close()">
        Close
      </button>
    </div>
  }
  @if (!sent) {
    @if (sending) {
      <app-spinner></app-spinner>
    }
    @if (!sending) {
      <form (submit)="send()">
        <div>
          <mat-form-field appearance="outline" floatLabel="always">
            <mat-label>Reason</mat-label>
            <mat-select name="reason" matInput [(ngModel)]="reason" placeholder="Choose a reason">
              @for (reason of reasons; track reason) {
                <mat-option [value]="reason.id">{{reason.name}}</mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>
        <div>
          <mat-form-field appearance="outline" floatLabel="always">
            <mat-label>Additional Details</mat-label>
            <textarea matInput name="additionalDetails" [(ngModel)]="additionalDetails" cdkTextareaAutosize></textarea>
          </mat-form-field>
        </div>
        <div>
          <button mat-raised-button [disabled]="!valid">
            Hide Content
          </button>
        </div>
      </form>
      <footer>
        Review our <a [href]="coreValuesUrl" target="_blank">Core Values</a><br />
        {{ subjectType }} {{ subjectId || externalId }}
      </footer>
    }
  }
}