import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { AfterViewInit, Component, ElementRef, inject, Input, NgZone, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ChatMessage, CommentsOrder, User } from "@banta/common";
import { BantaCommentsComponent, ChatSource, HashTag, MessageMenuItem } from "@banta/sdk";
import { ApiUser, CommentsApi, ImpressionsApi, SharesApi } from '@tytapp/api';
import { LoggerService, Shell } from "@tytapp/common";
import { environment } from "@tytapp/environment";
import { isClientSide, rewriteUrl } from '@tytapp/environment-utils';
import { HostApi } from '@tytapp/common';
import { LoginComponent, UserAuthMediatorComponent, UserService } from "@tytapp/user";
import { Subject, Subscription } from 'rxjs';
import { ReportComponent } from "../../moderation";
import { NotificationsService } from '@tytapp/notifications';


@Component({
    selector: 'tyt-comments',
    styleUrls: ['./comments.component.scss'],
    templateUrl: './comments.component.html'
})
export class TYTCommentsComponent implements AfterViewInit {
    private shell = inject(Shell);
    private userService = inject(UserService);
    private router = inject(Router);
    private commentService = inject(CommentsApi);
    private elementRef: ElementRef<HTMLElement> = inject(ElementRef);
    private ngZone = inject(NgZone);
    private impressionsApi = inject(ImpressionsApi);
    private overlay = inject(Overlay);
    private hostApi = inject(HostApi);
    private shareApi = inject(SharesApi);
    private notifications = inject(NotificationsService);
    private logger = inject(LoggerService);

    customMenuItems: MessageMenuItem[] = [];

    /**
     * The canonical URL to attach comments to. If not specified, uses current URL.
     */
    @Input()
    get url() {
        return this._url;
    }

    set url(value) {
        if (!value) {
            this._url = undefined;
            return;
        }

        if (value.startsWith('/')) {
            value = `${environment.urls.webRoot}${value}`;
        }

        this._url = value?.replace(/^(tyt|https?):\/\/mobile.tyt.com\//, environment.urls.webRoot);
    }

    private _url: string;

    @Input()
    identifier: string;

    /**
     * Metadata to pass down to Banta. This may be needed for authentication purposes (for instance, in the case of
     * a Comments block in ContentView which has requirementType=registration-- the tyt-banta-server system needs to
     * be able to validate that the page indeed calls for requirementType=registration in order to allow non-members
     * to comment)
     */
    @Input()
    metadata: Record<string, any> = {};

    @Input()
    showHeader = true;

    @Input()
    requirementType: 'membership' | 'registration' = 'membership';

    @Input()
    shareTitle: string;

    @Input()
    defaultSortOrder: CommentsOrder = CommentsOrder.LIKES;

    private _state: 'ready' | 'loading' = 'loading';

    get state() {
        if (!isClientSide())
            return 'disabled';
        if (!this.url)
            return 'missing-url';
        return this._state;
    }

    readyToLoad = false;
    hashtags: HashTag[] = [];

    showSignIn() {
        this.userService.source = `${this.identifier}_comments`;
        return this.shell.showDialog(UserAuthMediatorComponent);
    }

    editAvatar() {
        window.open(`/settings/profile`, '_blank');
    }

    async showPermissionDenied(reason: string) {
        await this.userService.ready;

        if (reason === 'membership-required') {
            if (this.userService.user?.entitled) {
                alert('You cannot comment on this content with your current plan.');

                if (await this.hostApi.hasCapability('web_billing:membership'))
                    window.open(`${environment.urls.accounts}/membership/change`, '_blank');

            } else {
                this.router.navigateByUrl(`/join/membership`);
            }
        } else if (reason === 'username-required') {
            const currentRoute = this.router.routerState.snapshot.url;
            this.router.navigateByUrl(`/settings/account/change-username?return=${currentRoute}`);
        } else if (reason === 'vote-required') {
            if (this.userService.user?.entitled)
                alert('You must vote in this poll before commenting.')
            else
                alert('You must vote in this poll or become a member before commenting.')
            return;
        } else if (reason === 'signature-required') {
            if (this.userService.user?.entitled)
                alert('You must sign this petition before commenting.');
            else
                alert('You must sign this petition or become a member before commenting.');
            return;
        } else {
            // Should not happen
            alert(`You cannot currently comment on this topic (${reason})`);
        }
    }

    async reportMessage(message: ChatMessage) {
        await this.userService.ready;
        if (!this.userService.user) {
            this.shell.showDialog(LoginComponent, { reason: "Sign in to report this content" });
        } else {
            this.shell.showDialog(ReportComponent, { subjectType: 'Comment', externalId: message.id });
        }
    }

    navigateToPublicProfile(user: User) {
        window.open(`/@${user.username}`, '_blank');
    }

    sharedComment: ChatMessage;
    @ViewChild(BantaCommentsComponent) bantaComponent: BantaCommentsComponent;
    @ViewChild('selectorPanelTemplate') selectorPanelTemplate: TemplatePortal<any>;

    overlayRef: OverlayRef;

    shareComment(message: ChatMessage) {
        this.sharedComment = message;
        const commentElement = this.bantaComponent.getCommentComponentForMessage(message);
        if (!commentElement) return;

        this.overlayRef = this.overlay.create({
            positionStrategy: this.overlay.position()
            .flexibleConnectedTo(commentElement.element)
            .withPositions([{
                originX: 'end',
                originY: 'bottom',
                overlayX: 'end',
                overlayY: 'top'
            }])
            .withFlexibleDimensions(true),
            hasBackdrop: true,
            disposeOnNavigation: true,
            scrollStrategy: this.overlay.scrollStrategies
            .reposition({autoClose: true})
        });
        this.overlayRef.attach(this.selectorPanelTemplate);
        this.overlayRef.backdropClick().subscribe(() => {
            this.overlayRef.detach();
        })
    }

    get sharedCommentURL() {
        const shareURL = new URL(this.url ?? window.location.href);
        shareURL.hash = '';
        shareURL.searchParams.set('comment', this.sharedComment.id)
        return shareURL.href;
    }

    async onShareComment(event: any) {
        const sharedOn = event.data.service;
        try {
            await this.shareApi.createShare({
                subject_type: 'Comment',
                external_id: this.sharedComment.id,
                mechanism: 'comments',
                platform_name: sharedOn,
                shared_at: (new Date()).toISOString(),
                subject_id: +this.sharedComment.id
            }).toPromise();
        } catch (e) {
            this.logger.error(`Failed to send share event to first-party analytics [the event will not be recorded]:`);
            this.logger.error(e);
        }

        this.overlayRef.detach();
    }

    isAPrivateReply = false;

    setPrivateReplyMode() {
        this.isAPrivateReply = true;
    }

    setPublicReplyMode() {
        this.isAPrivateReply = false;
    }

    showPrivateReplyInfo() {
        alert(
            `The private reply feature allows moderators `
            + ` to email a response to a comment instead of post it publically.`
        );
    }

    showPublicReplyInfo() {
        alert(
            `Your reply will be sent as a normal public comment reply that all users can see.`
        );
    }

    async interceptReplySend(chatMessage: ChatMessage, source: ChatSource) {
        if (!this.isAPrivateReply)
            return false;

        this.sendPrivateReply(chatMessage, source);
        return true;
    }

    async sendPrivateReply(chatMessage: ChatMessage, source: ChatSource): Promise<void> {
        try {
            const result = await this.commentService.createPrivateReply(source.parentIdentifier, {
                parent_comment_uuid: source.parentIdentifier,
                message: chatMessage.message
            }).toPromise();
            alert("Your private reply has been sent to the user's email address");
        } catch (error) {
            if (error.json)
                error = error.json();

            let message = error.message || error.error;

            switch (message) {
                case 'not-found':
                    alert('Cannot send a private reply to this comment as it has not been synchronized to TYT Platform yet. Please try again later.')
                    break;
                case 'unauthorized':
                    alert('Cannot send a private reply: Please reload TYT.com and try again.');
                    break;
                case 'forbidden':
                    alert('Cannot send a private reply: You do not have permission to perform this action.')
                    break;
            }
            this.logger.error(error);
        }

        this.isAPrivateReply = false;
    }

    get canPrivateReply() {
        return this.userService.user?.staff || this.userService.user?.trust_level > 1;
    }

    private recordedImpression = false;
    clientSide = isClientSide();

    async recordImpressions() {
        const impressionsEnabled = await this.shell.hasFeature('apps.web.impressions.record_for_comments');
        if (!impressionsEnabled) return;

        this.ngZone.runOutsideAngular(() => {
            new IntersectionObserver(async ([entry], observer) => {
                if (entry.isIntersecting) {
                    observer.unobserve(this.elementRef.nativeElement);
                    if (!this.recordedImpression) {
                        await this.impressionsApi.makeImpression(btoa(JSON.stringify({type: 'comments', id: this.identifier})));
                        this.recordedImpression = true;
                    }
                }
            }).observe(this.elementRef.nativeElement);
        });
    }

    private _subs = new Subscription();
    user: ApiUser;

    get isMember() {
        return this.user?.entitled ?? false;
    }

    allowSwitchBack = false;
    get billingEnabled() {
        return this.hostApi.capabilities.includes('web_billing:membership')
            || this.hostApi.capabilities.includes('platform_billing:membership');
    }

    disableSharing = false;

    @Input()
    handleNotifications = true;

    /**
     * Fired when this component needs to be visible to the user. For instance, when this component decides to handle
     * the user's click on a notification.
     */
    @Output() requiresFocus = new Subject<void>();

    async ngOnInit() {
        this._subs.add(this.notifications.addNotificationHandler(async notif => {


            if (!this.handleNotifications)
                return false;

            let notifUrl = rewriteUrl(environment, notif.url);
            let ourUrl = rewriteUrl(environment, this.url || window.location.href);

            // TODO: check if this notification points to a comment that we own, and if so,
            // navigate to it

            if (notifUrl.startsWith(`${ourUrl}?comment=`)) {
                let notifUrlObject = new URL(notifUrl);
                this.requiresFocus.next();

                // Give time for reacting to requiresFocus before proceeding.
                setTimeout(() => this.bantaComponent?.navigateToSharedComment(notifUrlObject.searchParams.get('comment')), 50);
                return true;
            }

            return false;
        }));

        this._subs.add(this.userService.userChanged.subscribe(user => {
            this.user = user;
            if (this.user && (this.user.trust_level > 0 || this.user.staff)) {
                this.customMenuItems = [
                    {
                        icon: 'traffic',
                        label: 'Moderation',
                        action: (message: ChatMessage) => {
                            window.open(`${environment.urls.platform}/admin/comments/${message.id}`, '_blank')
                        }
                    }
                ];
            } else {
                this.customMenuItems = [
                    // None
                ];
            }
        }));

        this._state = 'ready';
        this.readyToLoad = true;
        this.disableSharing = await this.shell.hasFeature('apps.web.disable_sharing');
    }

    ngOnDestroy() {
        this._subs.unsubscribe();
    }

    ngAfterViewInit() {
        this.recordImpressions();
    }

    get postReplyLabel() {
        if (this.isAPrivateReply)
            return `Reply privately via email`;
        else
            return `Post a reply`;
    }
}
