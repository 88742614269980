import { Component, inject } from '@angular/core';
import { ChatMessage } from '@banta/common';
import { SharesApi } from '@tytapp/api';
import { DialogComponent, NamedDialog } from '@tytapp/common';

@NamedDialog('shareComment')
@Component({
    templateUrl: `./share-comment.component.html`,
    styleUrls: ['./share-comment.component.scss']
})
export class ShareCommentComponent extends DialogComponent {
    public shareApi = inject(SharesApi);

    init(message: ChatMessage) {
        this.message = message;
    }

    message: ChatMessage;

    get url() {
        if (!this.message)
            return undefined;

        const shareURL = new URL(window.location.href);
        shareURL.hash = '';
        shareURL.searchParams.set('comment', this.message.id)
        return shareURL.href;
    }

    get title() {
        return ``;
    }

    async onShareComment(event: any) {
        const sharedOn = event.data.service;
        await this.shareApi.createShare({
            subject_type: 'Comment',
            external_id: this.message.id,
            mechanism: 'shareaholic',
            platform_name: sharedOn,
            shared_at: (new Date()).toISOString(),
            subject_id: +this.message.id
        }).toPromise();
        this.close();
    }

}